<template>
  <div class="taxe-carburant-list page-layout">
    <PageHeader
      title="Calculateurs de taxe sur vehicule société (carburant)"
      :has-actions="true"
      :has-back="true"
      @back="goBack"
    >
      <md-button
        class="md-primary md-raised"
        @click="
          $router.push(
            '/admin/calculators/taxe-vehicule-societe-carburant/create'
          )
        "
      >
        Ajouter
      </md-button>

      <md-button
        class="md-primary md-raised"
        @click="
          $router.push(
            '/admin/calculators/taxe-vehicule-societe-carburant/history'
          )
        "
      >
        Histoire
      </md-button>
    </PageHeader>

    <!-- La liste des calculators -->
    <sortable-list
      link="/admin/calculators/taxe-vehicule-societe-carburant/update/"
      :items="data"
      :parameters="listParameters"
      :hasPagination="true"
    >
    </sortable-list>
  </div>
</template>

<script>
import calculatorsModel from "../../../../model/calculators";
import PageHeader from "@/components/PageHeader";

export default {
  components: { PageHeader },
  data() {
    return {
      isDebug: false,
      data: [],
      listParameters: [
        {
          name: "Annee min",
          key: "annee_min",
        },
        {
          name: "Annee max",
          key: "annee_max",
        },
        {
          name: "Type",
          key: "type",
        },
        {
          name: "Montant",
          key: "montant",
        },
      ],
    };
  },
  methods: {
    goBack() {
      this.$router.push("/admin/calculators/");
    },
  },
  mounted() {
    calculatorsModel.getAllCarburant().then((data) => {
      this.data = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.impot-revenu-list {
  .debug-log {
    max-height: 200px;
    overflow-y: scroll;
  }
}
</style>
